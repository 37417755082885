import React from 'react';
import PropTypes from 'prop-types';
import { useFirmPaymentDetails } from '../../../../slices/billingHistorySlice';
import FetchDefaultInstitutionLogo from './FetchDefaultInstitutionLogo';

const DefaultPaymentMethod = ({ setCurrentActive, currentActive }) => {
  const firmPaymentDetails = useFirmPaymentDetails();
  const defaultCard =
    firmPaymentDetails?.firm_payment_methods?.find((items) => items?.is_active) ||
    firmPaymentDetails?.firm_payment_methods?.[0];
  const zeroCards = firmPaymentDetails?.firm_payment_methods?.length;
  const objectOfCards =
    defaultCard &&
    defaultCard?.payment_method_response &&
    JSON.parse(defaultCard?.payment_method_response);
  const paymentTitle = () => {
    if (defaultCard?.payment_method_type === 1) {
      if (objectOfCards?.card?.funding === 'credit') {
        return 'Credit Card';
      } else {
        return 'Debit Card';
      }
    } else {
      return 'Bank Account';
    }
  };
  return (
    <div
      className={`credit-change-method ${!currentActive && zeroCards && 'change-method-active'}`}
      onClick={() => {
        setCurrentActive && setCurrentActive(null);
      }}>
      {zeroCards ? (
        <div className="change-credit-text">
          <p className="cs-regular-body-text-l cs-neutral-80">{paymentTitle()}</p>
          <span className="change-card-image cs-semi-bold-h3 cs-neutral-100">
            <div className="cs-card-logo">
              <FetchDefaultInstitutionLogo
                img={
                  objectOfCards?.card
                    ? objectOfCards?.card.brand
                    : objectOfCards?.us_bank_account
                      ? 'Bank Account'
                      : ''
                }
              />
            </div>
            {`**** **** **** ${defaultCard?.last_4_digit ? defaultCard?.last_4_digit : '****'}`}
          </span>
        </div>
      ) : (
        <div className="no-cs-card-message cs-regular-h5 cs-neutral-60">
          No Default Card Selected
        </div>
      )}
    </div>
  );
};
DefaultPaymentMethod.propTypes = {
  currentActive: PropTypes.object,
  setCurrentActive: PropTypes.func
};
export default DefaultPaymentMethod;
