import React from 'react';
import Skeleton from 'react-loading-skeleton';

const PaymentMethodSkeleton = () => {
  return (
    <div className="billing-box-skeleton">
      <div className="payment-cards">
        <div className="payment-method">
          <span className="cs-regular-sub-heading-m  cs-neutral-100">
            <Skeleton width={100} />
          </span>
          <div className="text-button cursor-pointer">
            <div className="sidebar-add-account">
              <Skeleton width={90} />
            </div>
          </div>
        </div>
        <div className="credit-change-method">
          <div className="change-credit-text">
            <p className="cs-regular-body-text-l cs-neutral-80">
              <Skeleton width={120} />
            </p>
            <div className="d-flex">
              <div className="cs-card-logo">
                <Skeleton width={52} height={34} />
              </div>
              <Skeleton width={120} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodSkeleton;
