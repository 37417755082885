import React from 'react';
import Skeleton from 'react-loading-skeleton';

const BillingHistorySkeleton = () => {
  return (
    <div className="billing-box-skeleton">
      <div className="billing-box cs-current-billing">
        <div className="billing-head">
          <h4 className="cs-regular-h4 cs-neutral-100">
            <Skeleton width={160} />
          </h4>
          <div className="subscription-plan-cards">
            <div className="card-plan-amount">
              <h2 className="cs-semi-bold-h1 cs-primary">
                <Skeleton width={50} />
              </h2>
            </div>
            <span className="cs-regular-h4 cs-neutral-70">
              <Skeleton width={120} />
            </span>
          </div>
        </div>
        <div className="card-specification cs-light-body-text-l ">
          <ul>
            <li>
              <span className="Skeleton-text">
                <Skeleton />
              </span>
              <div className="card-plan-amount">
                <h2 className="cs-semi-bold-h3 cs-neutral-100">
                  <Skeleton />
                </h2>
              </div>
            </li>
            <li>
              <span className="Skeleton-text">
                <Skeleton />
              </span>

              <div className="card-plan-amount">
                <h2 className="cs-semi-bold-h3 cs-neutral-100">
                  <Skeleton />
                </h2>
              </div>
            </li>
            <li>
              <span className="Skeleton-text">
                <Skeleton />
              </span>

              <div className="card-plan-amount">
                <h2 className="cs-semi-bold-h3 cs-neutral-100">
                  <Skeleton />
                </h2>
              </div>
            </li>
            <li>
              <span className="Skeleton-text">
                <Skeleton />
              </span>

              <div className="card-plan-amount">
                <h2 className="cs-semi-bold-h3 cs-neutral-100">
                  <Skeleton />
                </h2>
              </div>
            </li>
          </ul>
        </div>
        <div className="sidebar-add-account text-center">
          <Skeleton width={220} height={30} />
        </div>
      </div>
    </div>
  );
};
export default BillingHistorySkeleton;
