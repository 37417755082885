import React, { Fragment } from 'react';
import CapsyncIcon from '../../../../components/CapsyncIcon';
import FetchDefaultInstitutionLogo from './FetchDefaultInstitutionLogo';
import PropTypes from 'prop-types';

const CardPaymentDetails = (props) => {
  const {
    item,
    index,
    setCurrentActive,
    setShowDeleteModal,
    setBillingDetailModal,
    setDeleteId,
    currentActive
  } = props;
  const removePaymentMethod = (e) => {
    e.stopPropagation();
    setShowDeleteModal(true);
    setBillingDetailModal(false);
  };
  const cardDetails = item && JSON.parse(item?.payment_method_response);

  const paymentTitle = () => {
    if (item?.payment_method_type === 1) {
      if (cardDetails?.card?.funding === 'credit') {
        return 'Credit Card';
      } else {
        return 'Debit Card';
      }
    } else {
      return 'Bank Account';
    }
  };
  return (
    <Fragment key={index}>
      <div
        onClick={() => setCurrentActive(item)}
        className={`${currentActive?.id === item?.id && 'change-method-active'} credit-change-method`}>
        <div className="change-credit-text">
          <p className="cs-regular-body-text-l cs-neutral-80">{paymentTitle()}</p>
          <span className="change-card-image cs-semi-bold-h3 cs-neutral-100">
            <div className="cs-card-logo">
              <FetchDefaultInstitutionLogo
                img={
                  cardDetails?.card
                    ? cardDetails?.card.brand
                    : cardDetails?.us_bank_account
                      ? 'Bank Account'
                      : ''
                }
              />
            </div>
            {`**** **** **** ${item?.last_4_digit}`}
          </span>
        </div>
        <span
          className={`cs-danger change-card-delete`}
          onClick={(e) => {
            removePaymentMethod(e);
            setDeleteId(item?.payment_method_id);
          }}>
          <CapsyncIcon title="delete-outlined" size="22" />
        </span>
      </div>
    </Fragment>
  );
};
CardPaymentDetails.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  currentActive: PropTypes.object,
  setCurrentActive: PropTypes.func,
  setDeleteId: PropTypes.func,
  setShowDeleteModal: PropTypes.func,
  setBillingDetailModal: PropTypes.func
};
export default CardPaymentDetails;
