import React from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { formatNumberWithThousandSeparator } from '../../../../utils/common';

const SummaryTable = ({ data, columns, showTableData }) => {
  return (
    <div>
      <div className="cs-subcription-table">
        <h3 className="cs-regular-h3 neutral-100">Summary</h3>
        <Table className="cs-table">
          <thead className="cs-thead">
            <tr>
              {columns?.map((column, i) => (
                <th className={i === 2 ? 'text-end' : ''} key={i}>
                  {column?.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="cs-tbody">
            {data?.map((row, i) => (
              <tr key={i}>
                {columns.map((column, j) => {
                  return (
                    <td className={j === 2 ? 'text-end' : ''} key={i + j}>
                      {`${j === 2 ? '$' : ''} `} {column.renderCell(row)}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
          <tfoot className="cs-tfoot">
            <tr>
              <td colSpan={2}>Total</td>
              <td className="text-end">
                ${' '}
                {formatNumberWithThousandSeparator(
                  Number(showTableData?.reduce((acc, items) => acc + items?.price, 0))
                )}
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
    </div>
  );
};
SummaryTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  showTableData: PropTypes.object
};
export default SummaryTable;
