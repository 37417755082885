import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CapsyncIcon from '../../../../components/CapsyncIcon';
import { useUser } from '../../../../slices/authSlice';
import {
  changeDefaultPaymentMethod,
  createSetupIntent,
  getFirmPaymentDetails,
  removePaymentMethod,
  useFirmPaymentDetails
} from '../../../../slices/billingHistorySlice';
import CardPaymentDetails from './CardPaymentDetails';
import DefaultPaymentMethod from './DefaultPaymentMethod';
import Modal from '../../../../components/Modal';
import DeleteModal from '../../../../components/DeleteModal';
import { toast } from 'react-toastify';

const BillingCardDetailModal = (props) => {
  const { billingDetailModal, setBillingDetailModal, setShowStripeForm, setCardModalType } = props;
  const [currentActive, setCurrentActive] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const user = useUser();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const firmPaymentDetails = useFirmPaymentDetails();
  const defaultCard =
    firmPaymentDetails?.firm_payment_methods?.find((items) => items?.is_active) ||
    firmPaymentDetails?.firm_payment_methods?.[0];

  const cardDetails = firmPaymentDetails?.firm_payment_methods;
  const closeModal = () => {
    setBillingDetailModal(false);
    setCurrentActive(null);
  };
  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      firm_id: user?.firm_id,
      payment_method_id: currentActive?.payment_method_id,
      subscription_id: firmPaymentDetails?.subscription_id
    };
    try {
      const response = await dispatch(changeDefaultPaymentMethod(payload)).unwrap();
      if (response?.status) {
        await dispatch(getFirmPaymentDetails({ firm_id: user?.firm_id })).unwrap();
        closeModal();
      }
    } catch (error) {
      console.log('error :>> ', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await dispatch(removePaymentMethod({ id: deleteId })).unwrap();
      if (response?.status) {
        await dispatch(getFirmPaymentDetails({ firm_id: user?.firm_id })).unwrap();
        setBillingDetailModal(true);
        toast.success('Your payment method has been successfully deleted');
        setShowDeleteModal(false);
      }
    } catch (error) {
      console.log('error :>> ', error);
    } finally {
      setLoading(false);
    }
  };

  const addPaymentMethodFun = async () => {
    setCardModalType('AddOrChangeMethod');
    setShowStripeForm(true);
    setBillingDetailModal(false);
    await dispatch(
      createSetupIntent({
        firm_id: user?.firm_id
      })
    ).unwrap();
  };

  const modalBody = () => {
    return (
      <>
        <div className="cs-scroller">
          <p className="cs-regular-body-text-m cs-neutral-100">Default payment method</p>
          <DefaultPaymentMethod
            active={false}
            currentActive={currentActive}
            setCurrentActive={setCurrentActive}
          />
          {cardDetails?.length > 1 ? (
            <p className="cs-regular-body-text-m cs-neutral-100">Other payment method</p>
          ) : null}
          {cardDetails &&
            cardDetails
              .filter((item) => item?.payment_method_id !== defaultCard?.payment_method_id)
              .map((item, index) => (
                <CardPaymentDetails
                  key={index}
                  item={item}
                  index={index}
                  setShowDeleteModal={setShowDeleteModal}
                  currentActive={currentActive}
                  setCurrentActive={setCurrentActive}
                  setDeleteId={setDeleteId}
                  showOnPage={false}
                  setBillingDetailModal={setBillingDetailModal}
                />
              ))}
        </div>
        <Button className="cs-regular-h5 cs-btn-icon-tertiary" onClick={addPaymentMethodFun}>
          <span className="cs-primary">
            <CapsyncIcon title="add-filled" size="14" />
          </span>
          Add another method
        </Button>
      </>
    );
  };

  return (
    <>
      <Modal
        className="payment-method-modal "
        show={billingDetailModal}
        title="Add or Change method"
        body={modalBody()}
        saveButtonLabel="Continue"
        isCloseButton={false}
        loading={loading}
        disabled={!currentActive || loading}
        cancelButtonLabel="Cancel"
        handleOnSave={handleSubmit}
        handleOnCancel={closeModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="cs-md-modal"
      />
      <DeleteModal
        show={showDeleteModal}
        onHandleClose={() => {
          setShowDeleteModal(false);
          setBillingDetailModal(true);
        }}
        onHandleConfirm={handleDelete}
        disabled={loading}
        loading={loading}
        deleteBodyText="Are you sure you want to delete this image? Once deleted, this action cannot be reversed"
        deleteButtonText="Delete"
      />
    </>
  );
};

BillingCardDetailModal.propTypes = {
  setBillingDetailModal: PropTypes.any,
  billingDetailModal: PropTypes.any,
  setShowStripeForm: PropTypes.func,
  setCardModalType: PropTypes.any
};
export default BillingCardDetailModal;
