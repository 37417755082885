import React from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncIcon from '../../../components/CapsyncIcon';
import { DICTIONARY_WORDS } from '../../../constants/system';
import { checkConsecutiveLetters, checkDictionary } from '../../../utils/common';

/* ============================== PASSWORD REQUIREMENT ============================== */
const PasswordRequirement = ({ password }) => {
  const requirements = [
    { text: 'No spaces allowed', check: /^\S*$/ },
    { text: 'Min 8 characters', check: /^.{8,}$/ },
    { text: 'Max 20 characters', check: /^.{1,20}$/ },
    { text: 'Use lower case letters', check: /[a-z]/ },
    { text: 'Use upper case letters', check: /[A-Z]/ },
    { text: 'Use a number', check: /\d/ },
    { text: 'Use a symbol', check: /[~`!@#$%^&*(),.?":{}|<React.Fragment>+=_-]/ },
    {
      text: 'Strong password',
      check: DICTIONARY_WORDS
    }
  ];

  const isValid = (requirement) => {
    if (Array.isArray(requirement.check)) {
      if (password.length < 8) {
        return false;
      }

      if (checkConsecutiveLetters(password.toLowerCase())) {
        return false;
      }

      if (checkDictionary(password)) {
        return false;
      }
      return true;
    } else {
      if (requirement.check.test(password)) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <ul className="password-requirements">
      {requirements.map((requirement, index) => (
        <li
          key={index}
          className={`cs-light-body-text-s ${isValid(requirement) ? 'cs-success' : 'cs-danger'}`}>
          <span className={isValid(requirement) ? 'cs-success' : 'cs-danger'}>
            <CapsyncIcon
              title={isValid(requirement) ? 'check-outlined' : 'close-outlined'}
              size="14"
            />
          </span>
          {requirement.text}
        </li>
      ))}
    </ul>
  );
};

// PROPS TYPE
PasswordRequirement.propTypes = {
  password: PropTypes.string.isRequired
};

export default PasswordRequirement;
