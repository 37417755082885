import React from 'react';
import PropTypes from 'prop-types';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from './CapsyncIcon';
import CommonSpinner from './CommonSpinner';

/* ============================== MODAL ============================== */
const Modal = (props) => {
  const {
    show = false,
    title = '',
    handleClose,
    body,
    loading,
    btnSize = 'md-btn',
    handleOnSave,
    saveButtonLabel = '',
    cancelButtonLabel = '',
    handleOnCancel,
    disabled = false,
    className = 'cs-lg-modal',
    parentClassName = '',
    isCloseButton = true,
    modalFooterClass = ''
  } = props;

  return (
    <BootstrapModal
      show={show}
      onHide={handleClose}
      className={'cs-modalbox ' + parentClassName}
      dialogClassName={className}
      centered>
      <BootstrapModal.Header closeButton={isCloseButton}>
        <BootstrapModal.Title className="cs-semi-bold-h3 cs-neutral-100">
          {title}
        </BootstrapModal.Title>
        {isCloseButton && (
          <span className="cs-neutral-70 cs-close-btn" onClick={handleOnCancel}>
            <CapsyncIcon title="close-outlined" size="20" />
          </span>
        )}
      </BootstrapModal.Header>
      {body && <BootstrapModal.Body>{body}</BootstrapModal.Body>}
      {(saveButtonLabel || cancelButtonLabel) && (
        <BootstrapModal.Footer className={'cs-modal-btn ' + modalFooterClass}>
          {cancelButtonLabel && (
            <Button
              variant="secondary"
              className="cs-btn-secondary md-btn cs-regular-h5"
              onClick={handleOnCancel}>
              {cancelButtonLabel}
            </Button>
          )}
          {saveButtonLabel && (
            <Button
              variant="primary"
              type="submit"
              className={`cs-btn-primary cs-regular-h5 cs-position-relative ${btnSize}`}
              disabled={disabled}
              onClick={handleOnSave}>
              {saveButtonLabel}
              {loading && <CommonSpinner classParent="cs-modal-common-spinner" size={16} />}
            </Button>
          )}
        </BootstrapModal.Footer>
      )}
    </BootstrapModal>
  );
};

// PROPS TYPE
Modal.propTypes = {
  show: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
  handleClose: PropTypes.func,
  body: PropTypes.element,
  handleOnSave: PropTypes.func,
  btnSize: PropTypes.string,
  saveButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  handleOnCancel: PropTypes.func,
  className: PropTypes.string,
  parentClassName: PropTypes.string,
  isCloseButton: PropTypes.bool,
  disabled: PropTypes.bool,
  modalFooterClass: PropTypes.string
};

export default Modal;
