import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CapsyncIcon from '../../../../components/CapsyncIcon';
import Modal from '../../../../components/Modal';
import {
  getFirmPaymentDetails,
  storeManualPaymentMethod,
  useClientSecret
} from '../../../../slices/billingHistorySlice';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useUser } from '../../../../slices/authSlice';

const StripeCheckOutForm = ({
  setShowStripeForm,
  type,
  showStripeForm,
  setBillingDetailModal,
  commonPurchaseType,
  setPurchaseModalOpen
}) => {
  const [isStripeLoading, setIsStripeLoading] = useState(true);
  const user = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentType, setPaymentType] = useState('');
  const clientSecretDetails = useClientSecret();
  const clientSecret = clientSecretDetails?.client_secret;
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const handleCardNumberChange = (event) => {
    setPaymentType(event.value.type);
  };

  const CARD_ELEMENT_OPTIONS = {
    fields: {
      billingDetails: {
        address: {
          country: 'never'
        }
      }
    },
    style: {
      base: {
        color: '#999',
        fontWeight: 500,
        fontSize: '16px',
        fontSmoothing: 'antialiased'
      },
      invalid: {
        iconColor: '#ffc7ee',
        color: '#ffc7ee'
      }
    },
    applePay: {}
  };

  const handleSubmit = async (e) => {
    console.log('paymentType :>> ', paymentType);
    e.preventDefault();
    setIsLoading(true);
    try {
      await elements.submit();
      if (type === 'AddOrChangeMethod') {
        const resultTwo = await stripe.confirmSetup({
          elements,
          clientSecret: clientSecret,
          redirect: 'if_required',
          confirmParams: {
            payment_method_data: {
              billing_details: {
                address: {
                  country: 'US'
                }
              }
            },
            return_url: 'https://example.com'
          }
        });
        if (resultTwo?.setupIntent?.status === 'succeeded') {
          toast.success('Your Payment method has been set successfully');
          const response = await dispatch(
            storeManualPaymentMethod({
              firm_id: user?.firm_id,
              setup_intent_id: clientSecretDetails?.id
            })
          ).unwrap();
          if (response) {
            await dispatch(getFirmPaymentDetails({ firm_id: user?.firm_id })).unwrap();
            setShowStripeForm(false);
            if (response?.status) {
              toast?.success(response?.message);
            } else {
              toast?.error(response?.message);
            }
          }
        }
      } else {
        const result = await stripe.confirmPayment({
          elements,
          clientSecret: clientSecret,
          redirect: 'if_required',
          confirmParams: {
            payment_method_data: {
              billing_details: {
                address: {
                  country: 'US'
                }
              }
            },
            return_url: 'https://example.com' // Replace with your actual return URL
          }
        });
        if (result?.paymentIntent?.status === 'succeeded') {
          toast.success('New credits purchase successfully.');

          setShowStripeForm(false);
        }
      }
    } catch (err) {
      console.log('Error in handleSubmit: ', err);
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    if (commonPurchaseType) {
      setPurchaseModalOpen(true);
    }
    if (type === 'AddOrChangeMethod') {
      setPurchaseModalOpen(false);
    }
    setShowStripeForm && setShowStripeForm(false);
    setBillingDetailModal && setBillingDetailModal(false);
    setIsLoading(false);
  };

  const modalBody = () => (
    <div className="cs-form-group">
      <div className="cs-stripe-spinner">
        {isStripeLoading && (
          <span className="cs-common-spinner cs-primary m-auto">
            <CapsyncIcon title="loading-outlined" size="30" />
          </span>
        )}
      </div>
      <PaymentElement
        options={CARD_ELEMENT_OPTIONS}
        onChange={handleCardNumberChange}
        onReady={() => setIsStripeLoading(false)}
      />
    </div>
  );

  return (
    <Modal
      className="stripe-form"
      show={showStripeForm}
      title="Enter card details"
      body={modalBody()}
      saveButtonLabel="Continue"
      disabled={isLoading}
      loading={isLoading}
      cancelButtonLabel="Cancel"
      handleOnSave={handleSubmit}
      handleOnCancel={closeModal}
      isCloseButton={false}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="cs-md-modal"
    />
  );
};

StripeCheckOutForm.propTypes = {
  setShowStripeForm: PropTypes.func,
  type: PropTypes.string,
  setBillingDetailModal: PropTypes.func,
  setPurchaseModalOpen: PropTypes.func,
  commonPurchaseType: PropTypes.string,
  showStripeForm: PropTypes.bool
};

export default StripeCheckOutForm;
