import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Row, Col, Form, Button } from 'react-bootstrap';

// COMPONENT IMPORTS
import Modal from '../../../components/Modal';
import MfaOptionModal from './MfaOptionModal';
import { MFA_TYPES } from '../../../constants/system';
import ConfirmEditProfile from './ConfirmEditProfile';
import { TOAST_MESSAGE } from '../../../constants/message';
import { EditProfileValidationSchema } from '../validation';
import AddressSearch from '../../../components/AddressSearch';
import InputBox from '../../../components/FormFields/InputBox';
import PhoneInputField from '../../../components/FormFields/PhoneInputField';
import MFAVerificationModal from '../../../components/Modals/MFAVerificationModal';

// API
import {
  generateMFAOTP,
  getUserDetails,
  updateUserDetails,
  useUser
} from '../../../slices/authSlice';

/* ============================== EDIT PROFILE ============================== */
const EditProfie = ({ setEditProfileOpen, editProfileOpen }) => {
  const dispatch = useDispatch();
  const user = useUser();
  const addressString = user?.user_personal_details?.address;
  const address =
    typeof addressString === 'string' && addressString.trim()
      ? JSON.parse(addressString || '{}')
      : {};
  const [mfaType, setMfaType] = useState('');
  const [mfaVerificationModal, setMfaVerificationModal] = useState(false);
  const [mfaOptionModal, setMfaOptionModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [initialValues, setInitialValues] = useState({
    first_name: '',
    firm_name: '',
    last_name: '',
    email: '',
    crd_number: '',
    street_name: '',
    phone_no: '',
    city: '',
    state: '',
    zip_code: '',
    country: 'USA'
  });
  const [addressObj, setAddressObj] = useState({
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: '',
    postal_code: ''
  });

  useEffect(() => {
    setInitialValues({
      first_name: user?.first_name,
      firm_name: user?.firm?.firm_name,
      last_name: user?.last_name,
      email: user?.email,
      phone_no: user?.phone_no,
      crd_number: user?.firm?.crd_number,
      street_name: address?.line1,
      city: address?.city,
      state: address?.state,
      country: address?.country,
      zip_code: address?.postal_code
    });
    setAddressObj(address);
  }, [user]);

  const handleMfaModalClose = () => {
    setMfaOptionModal(false);
  };

  const handleMFAVerifyClose = () => {
    setMfaVerificationModal(false);
  };

  const handleMFAOption = () => {
    if (mfaType === MFA_TYPES.EMAIL) {
      dispatch(generateMFAOTP({ mfaType: MFA_TYPES.EMAIL, payload: { user_id: user && user.id } }));
    } else if (mfaType == MFA_TYPES.PHONE) {
      dispatch(generateMFAOTP({ mfaType: MFA_TYPES.PHONE, payload: { user_id: user && user.id } }));
    }
    handleMfaModalClose();
    setMfaVerificationModal(true);
  };

  const updateUser = async (values) => {
    const payload = {
      user_id: String(user?.id),
      first_name: values?.first_name,
      last_name: values?.last_name,
      phone_no: values?.phone_no,
      address: addressObj
    };
    try {
      const response = await dispatch(updateUserDetails(payload)).unwrap();
      if (response?.status) {
        await dispatch(getUserDetails(`${user?.id}`)).unwrap();
        setEditProfileOpen(false);
        toast.success(TOAST_MESSAGE.EDIT_PROFILE_SUCCESS);
      }
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  const {
    values,
    errors,
    touched,
    status,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    setStatus,
    resetForm,
    submitCount,
    isSubmitting
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: EditProfileValidationSchema,
    onSubmit: async (values) => {
      if (user?.phone_no !== values?.phone_no) {
        setEditProfileOpen(false);
        setShowConfirmModal(true);
      } else {
        updateUser(values);
      }
    }
  });

  const handleSuccess = () => {
    updateUser(values);
    resetForm();
  };

  useEffect(() => {
    if (editProfileOpen) resetForm();
  }, [editProfileOpen]);

  const body = (
    <Form onSubmit={handleSubmit} className="personal-inform-form">
      <Row>
        <Col className="col" lg={6} md={6} sm={6}>
          <InputBox
            label="First name"
            name="first_name"
            placeholder="Enter first name"
            values={values}
            errors={errors}
            touched={touched}
            disabled={false}
            onChange={handleChange}
          />
        </Col>
        <Col className="col" lg={6} md={6} sm={6}>
          <InputBox
            label="Last name"
            name="last_name"
            placeholder="Enter Last name"
            values={values}
            errors={errors}
            touched={touched}
            disabled={false}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={6} sm={6}>
          <InputBox
            label="Email"
            name="email"
            placeholder="Enter email address"
            values={values}
            errors={errors}
            touched={touched}
            disabled={true}
          />
        </Col>
        <Col lg={6} md={6} sm={6}>
          <PhoneInputField
            label={'Phone Number'}
            name={'phone_no'}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            submitCount={submitCount}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <InputBox
            label="Company Name"
            name="firm_name"
            placeholder="Enter Company Name"
            values={values}
            errors={errors}
            touched={touched}
            disabled={true}
            onChange={handleChange}
          />
        </Col>
        <Col sm={6}>
          {user?.firm?.crd_number && (
            <InputBox
              label="CRD number"
              name="crd_number"
              disabled={true}
              placeholder="Enter CRD number"
              values={values}
              errors={errors}
              touched={touched}
              onChange={handleChange}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <AddressSearch
            errors={errors}
            touched={touched}
            disabled
            values={values}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            setAddressObj={setAddressObj}
            setStatus={setStatus}
            status={status}
            addressObj={addressObj}
            setFieldTouched={setFieldTouched}
          />
        </Col>
      </Row>
      <div className="cs-modal-btn">
        <Button
          className="cs-btn-secondary md-btn cs-regular-h5"
          type="button"
          onClick={() => {
            setEditProfileOpen(false);
            resetForm();
          }}>
          Cancel
        </Button>

        <Button
          type="submit"
          className="cs-btn-primary md-btn cs-regular-h5"
          disabled={
            !Object.keys(initialValues).some(
              (field) => (values[field] || '') !== (initialValues[field] || '')
            ) || isSubmitting
          }>
          Update
        </Button>
      </div>
    </Form>
  );

  return (
    <React.Fragment>
      <Modal title={'Edit Profile'} show={editProfileOpen} body={body} isCloseButton={false} />
      <MfaOptionModal
        title={'PFS password'}
        modalState={mfaOptionModal}
        handleOnCancel={handleMfaModalClose}
        handleOnSave={handleMFAOption}
        subHeader={'Choose your preferred authentication method'}
        callback={setMfaType}
        selectedOption={mfaType}
      />
      <MFAVerificationModal
        modalState={mfaVerificationModal}
        handleModalClose={handleMFAVerifyClose}
        mfaType={mfaType}
        handleSuccess={handleSuccess}
      />
      <ConfirmEditProfile
        setMfaOptionModal={setMfaOptionModal}
        showConfirmModal={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
      />
    </React.Fragment>
  );
};

// PROPS TYPE
EditProfie.propTypes = {
  setEditProfileOpen: PropTypes.func,
  editProfileOpen: PropTypes.bool
};

export default EditProfie;
