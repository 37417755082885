import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

// COMPONENT IMPORTS
import { checkConsecutiveLetters, checkDictionary, checkDomainAccept } from '../utils/common';
import { usAreaCodes } from '../config/countryAreaCodes';
import { VALIDATION_MESSAGE } from '../constants/message';

export const emailField = () =>
  yup
    .string()
    .matches(/^\S*$/, VALIDATION_MESSAGE.EMAIL_WITHOUT_SPACE)
    .required(VALIDATION_MESSAGE.EMAIL_REQUIRED)
    .email(VALIDATION_MESSAGE.INVALID_EMAIL)
    .test(
      'no-more-than-30-before-at',
      VALIDATION_MESSAGE.INVALID_EMAIL,
      (value) => value.split('@')[0].length <= 30
    )
    .test(
      'at-least-1-character-before-at',
      VALIDATION_MESSAGE.REQUIRED_ONE_CHARACTERS_BEFORE_AT_SIGN,
      (value) => value.split('@')[0].length > 0
    )
    .test('custom-email-format', VALIDATION_MESSAGE.INVALID_EMAIL, (value) => {
      return /^[a-z0-9.]+([_+][a-z0-9.]+)*@[a-z0-9.-]+\.[a-z]{2,}$/i.test(value);
    })
    .test('domain-acceptance', VALIDATION_MESSAGE.INVALID_EMAIL, (value) => {
      return checkDomainAccept(value.toLowerCase());
    });

export const phoneField = ({ isRequired } = { isRequired: true }) => {
  let obj = yup.string().test('is-valid-phone', 'Invalid phone number', async (value) => {
    if (!value) return true;
    return (
      isValidPhoneNumber(value) && usAreaCodes.includes(Number(value.split('+1')[1].slice(0, 3)))
    );
  });

  if (!isRequired) {
    obj = obj.nullable();
  } else {
    obj = obj.required(VALIDATION_MESSAGE.PHONE_REQUIRED);
  }
  return obj;
};

export const signInPasswordField = () =>
  yup
    .string()
    .required(VALIDATION_MESSAGE.PASSWORD_REQUIRED)
    .matches(/^\S*$/, VALIDATION_MESSAGE.INCORRECT_PASSWORD)
    .matches(/[0-9]/, VALIDATION_MESSAGE.INCORRECT_PASSWORD)
    .matches(/[a-z]/, VALIDATION_MESSAGE.INCORRECT_PASSWORD)
    .matches(/[A-Z]/, VALIDATION_MESSAGE.INCORRECT_PASSWORD)
    .matches(/[~`!@#$%^&*(),.?":{}|<React.Fragment>+=_-]/, VALIDATION_MESSAGE.INCORRECT_PASSWORD)
    .min(8, VALIDATION_MESSAGE.INCORRECT_PASSWORD);

export const passwordField = () =>
  yup
    .string()
    .required(VALIDATION_MESSAGE.PASSWORD_REQUIRED)
    .matches(/^\S*$/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .matches(/[0-9]/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .matches(/[a-z]/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .matches(/[A-Z]/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .matches(/[^A-Za-z0-9]/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .matches(/[~`!@#$%^&*(),.?":{}|<React.Fragment>+=_-]/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .min(8, VALIDATION_MESSAGE.VALID_PASSWORD)
    .max(20, VALIDATION_MESSAGE.VALID_PASSWORD);

export const strongWeakPasswordField = () =>
  yup
    .string()
    .required(VALIDATION_MESSAGE.PASSWORD_REQUIRED)
    .test('strongWeakPassword', VALIDATION_MESSAGE.STRONG_WEAK_PASSWORD, (value) => {
      if (value.length < 8) {
        return false;
      }
      if (checkConsecutiveLetters(value.toLowerCase())) {
        return false;
      }
      if (checkDictionary(value)) {
        return false;
      }
      return true;
    })
    .matches(/^\S*$/, VALIDATION_MESSAGE.NO_SPACE)
    .matches(/[0-9]/, VALIDATION_MESSAGE.USE_NUMBER)
    .matches(/[a-z]/, VALIDATION_MESSAGE.USER_LOWERCASE)
    .matches(/[A-Z]/, VALIDATION_MESSAGE.USER_UPPERCASE)
    .matches(/^(?=.*(_|[^\w])).+$/, VALIDATION_MESSAGE.USE_SYMBOL)
    .min(8, VALIDATION_MESSAGE.REQUIRED_EIGHT_OR_MORE_CHARACTERS)
    .max(20, VALIDATION_MESSAGE.REQUIRED_TWENTY_CHARACTERS);

export const confirmPasswordField = () =>
  yup
    .string()
    .oneOf([yup.ref('password'), null], VALIDATION_MESSAGE.PASSWORD_NOT_MATCH)
    .required(VALIDATION_MESSAGE.CONFIRM_PASSWORD_REQUIRED)
    .matches(/^\S*$/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .max(20, VALIDATION_MESSAGE.VALID_PASSWORD);

export const firstNameField = () =>
  yup
    .string()
    .strict()
    .required(VALIDATION_MESSAGE.FIRST_NAME_REQUIRED)
    .min(2, VALIDATION_MESSAGE.REQUIRED_TWO_CHARACTERS)
    .max(40, VALIDATION_MESSAGE.REQUIRED_FORTY_CHARACTERS);

export const lastNameField = () =>
  yup
    .string()
    .strict()
    .required(VALIDATION_MESSAGE.LAST_NAME_REQUIRED)
    .min(2, VALIDATION_MESSAGE.REQUIRED_TWO_CHARACTERS)
    .max(40, VALIDATION_MESSAGE.REQUIRED_FORTY_CHARACTERS);

export const companyNameField = () =>
  yup
    .string()
    .strict()
    .required(VALIDATION_MESSAGE.COMPANY_NAME_REQUIRED)
    .matches(/^\S(?:.*\S)?$/, VALIDATION_MESSAGE.NO_SPACE)
    .min(2, VALIDATION_MESSAGE.REQUIRED_TWO_CHARACTERS)
    .max(30, VALIDATION_MESSAGE.REQUIRED_THIRTY_CHARACTERS)
    .matches(/^(?!.*[^\s]\.[^\s]).*$/, VALIDATION_MESSAGE.DOT_NOT_ALLOWED);

export const crdNumberField = () =>
  yup
    .string()
    .strict()
    .matches(/^\S(?:.*\S)?$/, VALIDATION_MESSAGE.NO_SPACE)
    .min(2, VALIDATION_MESSAGE.REQUIRED_TWO_CHARACTERS)
    .max(20, VALIDATION_MESSAGE.REQUIRED_TWENTY_CHARACTERS)
    .required(VALIDATION_MESSAGE.CRD_NUMBER_REQUIRED);

export const streetNameField = () =>
  yup
    .string()
    .required(VALIDATION_MESSAGE.STREET_NAME_REQUIRED)
    .matches(/(?=.*?\d)/, VALIDATION_MESSAGE.INVALID_STREET)
    .matches(/[A-Za-z]{2}/, VALIDATION_MESSAGE.INVALID_STREET);

export const cityField = () =>
  yup.string().required(VALIDATION_MESSAGE.CITY_REQUIRED).min(2, VALIDATION_MESSAGE.INVALID_CITY);

export const zipcodeField = () =>
  yup
    .string()
    .required(VALIDATION_MESSAGE.ZIPCODE_REQUIRED)
    .matches(/^[0-9]+$/, VALIDATION_MESSAGE.INVALID_ZIPCODE)
    .min(5, VALIDATION_MESSAGE.INVALID_ZIPCODE)
    .max(5, VALIDATION_MESSAGE.INVALID_ZIPCODE);

export const textAreaField = (fieldname) =>
  yup
    .string()
    .required('Please enter ' + fieldname)
    // .matches(/^\S.*$/, VALIDATION_MESSAGE.WHITESPACE)
    .max(255, VALIDATION_MESSAGE.TWO_HUNDRED_FIFTY_FIVE_CHARACTERS_ONLY)
    .min(50, VALIDATION_MESSAGE.MUST_BE_FIFTY_CHARACTERS);

export const dateField = () =>
  yup
    .date()
    .required(VALIDATION_MESSAGE.DATE_REQUIRED)
    .max(new Date(), VALIDATION_MESSAGE.FUTURE_DATE_NOT_ALLOWED);

export const websiteField = () =>
  yup
    .string()
    .required(VALIDATION_MESSAGE.WEBSITE_REQUIRED)
    .matches(/^\S.*$/, VALIDATION_MESSAGE.WHITESPACE)
    .test('whitesspace', VALIDATION_MESSAGE.WHITESPACE, (value) => !/^\s+|\s+$/g.test(value))
    .max(256, VALIDATION_MESSAGE.URL_MAX_LENGTH_EXCEEDED_MESSAGE)
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      VALIDATION_MESSAGE.CORRECT_URL
    );

export const domainNameField = () =>
  yup
    .string()
    .required(VALIDATION_MESSAGE.DOMAIN_NAME_REQUIRED)
    .matches(/^\S.*$/, VALIDATION_MESSAGE.WHITESPACE)
    .test('whitesspace', VALIDATION_MESSAGE.WHITESPACE, (value) => !/^\s+|\s+$/g.test(value))

    .matches(/^[A-Za-z0-9-]*$/, VALIDATION_MESSAGE.DOMAIN_NAME_ALLOWS)
    .matches(/^[A-Za-z0-9][A-Za-z0-9-]{0,61}[A-Za-z0-9]$/, VALIDATION_MESSAGE.DOMAIN_NAME_START_END)
    .max(63, VALIDATION_MESSAGE.DOMAIN_NAME_MAX);
// .matches(
//   /^(?!-)(?=.*[a-zA-Z])[a-zA-Z\d-]*[a-zA-Z]+[a-zA-Z\d-]*(?<!-)$/,
//   VALIDATION_MESSAGE.CORRECT_DOMAIN
// )

export const welcomeMessageField = (isChecked) => {
  return isChecked
    ? yup
      .string()
      .strict()
      .matches(/^[^\s].*$/, VALIDATION_MESSAGE.NO_SPACE)
      .max(60, VALIDATION_MESSAGE.WELCOME_MSG_NOT_MORE_SIXTY_CHARACTERS)
      .required(VALIDATION_MESSAGE.WELCOME_MSG_REQUIRED)
    : yup
      .string()
      .strict()
      .matches(/^[^\s].*$/, VALIDATION_MESSAGE.NO_SPACE)
      .max(60, VALIDATION_MESSAGE.WELCOME_MSG_NOT_MORE_SIXTY_CHARACTERS);
};

export const welcomeMessageTextAreaField = (isChecked) => {
  return isChecked
    ? yup
      .string()
      .strict()
      .matches(/^[^\s].*$/, VALIDATION_MESSAGE.NO_SPACE)
      .max(500, VALIDATION_MESSAGE.WELCOME_MSG_TEXT_AREA_NOT_MORE_FIVE_HUNDRED_CHARACTERS)
      .required(VALIDATION_MESSAGE.WELCOME_MSG_REQUIRED)
    : yup
      .string()
      .strict()
      .matches(/^[^\s].*$/, VALIDATION_MESSAGE.NO_SPACE)
      .max(500, VALIDATION_MESSAGE.WELCOME_MSG_TEXT_AREA_NOT_MORE_FIVE_HUNDRED_CHARACTERS);
};

export const otpValidationSchema = () =>
  yup.object({
    otp: yup
      .string()
      .required(VALIDATION_MESSAGE.OTP_IS_REQUIRED)
      .min(6, VALIDATION_MESSAGE.INVALID_OTP)
  });

export const fileCurrPassField = () => yup.string().required(VALIDATION_MESSAGE.PASSWORD_REQUIRED);
// .matches(/^\S*$/, VALIDATION_MESSAGE.INCORRECT_PASSWORD)
// .matches(/[0-9]/, VALIDATION_MESSAGE.INCORRECT_PASSWORD)
// .matches(/[a-z]/, VALIDATION_MESSAGE.INCORRECT_PASSWORD)
// .matches(/[A-Z]/, VALIDATION_MESSAGE.INCORRECT_PASSWORD)
// .matches(/^(?=.*(_|[^\w])).+$/,VALIDATION_MESSAGE.INCORRECT_PASSWORD)
// .min(8,VALIDATION_MESSAGE.REQUIRED_EIGHT_OR_MORE_CHARACTERS)
// .max(20, VALIDATION_MESSAGE.PASSWORD_NOT_MORE_TWENTY_CHARACTERS)

export const fileNewPassField = () =>
  yup
    .string()
    .required(VALIDATION_MESSAGE.PASSWORD_REQUIRED)
    .matches(/^\S*$/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .matches(/[0-9]/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .matches(/[a-z]/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .matches(/[A-Z]/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .matches(/[^A-Za-z0-9]/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .matches(/[~`!@#$%^&*(),.?":{}|<React.Fragment>+=_-]/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .notOneOf([yup.ref('current_password')], VALIDATION_MESSAGE.NEW_PASSWORD_MATCH)
    .min(8, VALIDATION_MESSAGE.VALID_PASSWORD)
    .max(20, VALIDATION_MESSAGE.VALID_PASSWORD);

export const fileConfirmPassField = () =>
  yup
    .string()
    .matches(/^\S*$/, VALIDATION_MESSAGE.VALID_PASSWORD)
    .required(VALIDATION_MESSAGE.CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref('new_password'), null], VALIDATION_MESSAGE.PASSWORD_NOT_MATCH)
    .min(8, VALIDATION_MESSAGE.VALID_PASSWORD)
    .max(20, VALIDATION_MESSAGE.VALID_PASSWORD);
export const professionalLicensesField = () =>
  yup
    .number()
    .required(VALIDATION_MESSAGE.CONFIRM_PASSWORD_REQUIRED)
    .max(20, VALIDATION_MESSAGE.VALID_PASSWORD);
