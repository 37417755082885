import React from 'react';
import Skeleton from 'react-loading-skeleton';

const BillingDateSkeleton = () => {
  return (
    <div className="billing-box-skeleton">
      <div className="billing-box">
        <h4 className="cs-regular-h4 cs-neutral-100">
          {' '}
          <Skeleton width={160} />
        </h4>
        <div className="cs-flex">
          <div className="last-billing">
            <span className="cs-regular-h4 cs-neutral-70">
              {' '}
              <Skeleton width={120} />
            </span>
            <h2 className="cs-semi-bold-h1 cs-primary">
              <Skeleton width={120} />
            </h2>
          </div>
          <span className="cs-neutral-100">
            <Skeleton width={20} />
          </span>
          <div className="next-billing">
            <span className="cs-regular-h4 cs-neutral-70">
              {' '}
              <Skeleton width={120} />
            </span>
            <h2 className="cs-semi-bold-h1 cs-primary">
              <Skeleton />
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingDateSkeleton;
