const VALIDATION_MESSAGE = {
  WHITESPACE: 'Whitespace is not allowed',
  FIRST_NAME_REQUIRED: 'Enter first name',
  LAST_NAME_REQUIRED: 'Enter last name',
  EMAIL_REQUIRED: 'Enter email address',
  EMAIL_WITHOUT_SPACE: 'Email cannot contain spaces',
  PHONE_REQUIRED: 'Enter phone number',
  PASSWORD_REQUIRED: 'Enter password',
  STRONG_WEAK_PASSWORD: 'Weak password',
  INCORRECT_PASSWORD: 'Incorrect password',
  VALID_PASSWORD: 'Enter a valid password',
  CONFIRM_PASSWORD_REQUIRED: 'Confirm password',
  COMPANY_NAME_REQUIRED: 'Enter company name',
  CRD_NUMBER_REQUIRED: 'Please enter CRD number',
  NO_SPACE: 'No space allowed',
  INVALID_EMAIL: 'Enter a valid email address',
  NEW_PASSWORD_MATCH: 'New password cannot be same as old password.',
  USE_SYMBOL: 'Use a symbol',
  USE_NUMBER: 'Use a number',
  USER_LOWERCASE: 'Use a lowercase letter',
  USER_UPPERCASE: 'Use an uppercase letter',
  INVALID_CITY: 'Enter a valid city',
  REQUIRED_TWO_CHARACTERS: 'Required at least 2 characters',
  REQUIRED_EIGHT_OR_MORE_CHARACTERS: 'Use 8 or more characters',
  REQUIRED_TWENTY_CHARACTERS: 'Not allowed more than 20 characters',
  REQUIRED_FORTY_CHARACTERS: 'Maximum 40 characters allowed',
  PASSWORD_NOT_MORE_TWENTY_CHARACTERS: 'Password cannot exceed 20 characters',
  EMAIL_NOT_UPPERCASE: 'Email address should not contain capital letters',
  REQUIRED_THIRTY_CHARACTERS_BEFORE_AT_SIGN: 'Cannot accept more than 30 characters before @',
  REQUIRED_ONE_CHARACTERS_BEFORE_AT_SIGN: 'Enter at least 1 character before @',
  PASSWORD_NOT_MATCH: 'Passwords do not match',
  FILE_ONLY_IMAGE_ALLOWED: 'Only JPEG, JPG, or PNG format accepted.',
  FILE_ONLY_SINGLE_IMAGE_ALLOWED: 'Only single image upload allowed.',
  FILE_SIZE_LIMIT_EXCEED: 'File greater than 5 MB not accepted.',
  INVALID_PHONE_NUMBER: 'Enter a valid phone number',
  REQUIRED_MAX_TEN_CHARACTERS: 'Phone number should be 10 digits',
  REQUIRED_THIRTY_CHARACTERS: 'Not allowed more than 30 characters',
  DOT_NOT_ALLOWED: 'Make sure all dots (.) have spaces around them, or remove them completely.',
  STREET_NAME_REQUIRED: 'Enter street address',
  INVALID_STREET: 'Enter a valid address',
  MUST_HAVE_STREET_NUMBER: 'Address must have street number',
  INVALID_ROUTE: 'Enter a valid address',
  MUST_HAVE_ROUTE_DETAILS: 'Address must have route details',
  CITY_REQUIRED: 'Enter city',
  SPECIAL_CHARACTERS_AND_NUMBER_NOT_ALLOWED: 'Special characters and numbers are not allowed',
  ZIPCODE_REQUIRED: 'Enter ZIP code',
  INVALID_ZIPCODE: 'Enter a valid ZIP code',
  ONLY_DIGITS: 'Must be only digits',
  MUST_EXACTLY_FIVE_DIGITS: 'Must be exactly 5 digits',
  DETAILS_REQUIRED: 'Enter details',
  MUST_BE_FIFTY_CHARACTERS: 'Must be at least 50 characters long',
  TWO_HUNDRED_FIFTY_FIVE_CHARACTERS_ONLY: 'Must not exceeded 255 characters.',
  SELECT_ADVISOR: 'Select Registered Advisor',
  WEBSITE_REQUIRED: 'Enter website',
  CORRECT_URL: 'Enter correct url!',
  BUSINESS_TYPE_REQUIRED: 'Select business type',
  EIN_NUMBER_REQUIRED: 'Enter EIN number',
  EIN_NUMBER_FORMAT: 'It should be in "xx-xxxxxxx" format',
  MORE_THEN_NINE_DIGITS: 'Must be more than 9 digits',
  DATE_REQUIRED: 'Please select date',
  FUTURE_DATE_NOT_ALLOWED: 'Future date can not be accepted',
  NUMBER_OF_CLIENTS_REQUIRED: 'Select number of clients',
  TYPE_OF_CLIENT_REQUIRED: 'Select type of clients',
  INDUSTRY_REQUIRED: 'Select Industry',
  DOMAIN_NAME_MAX: 'Domain names cannot be longer than 63 characters.',
  DOMAIN_NAME_ALLOWS: 'Domain names can only contain letters, numbers, and hyphens.',
  DOMAIN_NAME_START_END: 'Domain names must start and end with a letter or number.',
  DOMAIN_NAME_REQUIRED: 'Enter domain name',
  CORRECT_DOMAIN: 'Enter correct domain!',
  OTP_IS_REQUIRED: 'Enter code',
  INVALID_OTP: 'Enter a valid code',
  OPT_MIN_LENGTH_SIX: 'Verification code must be 6 characters',
  INTERNAL_SERVER_ERROR: 'Internal server error!',
  WELCOME_MSG_NOT_MORE_SIXTY_CHARACTERS: 'Message cannot exceed 60 characters',
  WELCOME_MSG_TEXT_AREA_NOT_MORE_FIVE_HUNDRED_CHARACTERS: 'Message cannot exceed 500 characters',
  WELCOME_MSG_REQUIRED: 'Please enter welcome message',
  VALID_PHONE_NUMBER: 'Please enter a valid phone number',
  CITY_NOT_MATCH: 'City entered does not match the Zip code entered',
  STATE_NOT_MATCH: 'State entered does not match the Zip code entered',
  FILE_SIZE_EXCEED_ONE_MB: 'File greater than 1 MB not accepted.',
  MAX_FILE_NUMBER_EXCEED: 'Maximum three files can only be uploaded.',
  URL_MAX_LENGTH_EXCEEDED_MESSAGE: 'The URL must not exceed 256 characters.'
};

const TOAST_MESSAGE = {
  SIGN_UP_SUCCESS: 'User signed Up successfully',
  USER_ADDED_SUCCESS: 'User added successfully',
  FILE_CHANGE_PASSWORD_SUCCESS: 'Password updated successfully',
  CLIENT_DATA_EXPORT_SUCCESS: 'Data exported successfully',
  CLIENT_DATA_EXPORT_ERROR: 'The attempt to export data failed',
  SIGN_DOWNLOAD_SUCCESS: 'Signature downloaded successfully',
  SIGN_DOWNLOAD_ERROR: 'The attempt to export data failed',
  COMPANY_ADDED_SUCCESS: 'Company created successfully',
  MFA_SUCCESS: 'MFA Sucessful',
  EDIT_PROFILE_SUCCESS: 'Profile edited successfully',
  MFA_PHONE_SUCCESS: 'Phone Authentication successful',
  MFA_GOOGLE_SUCCESS: 'Google Authentication successful',
  MFA_GOOGLE_RESET_SUCCESS: 'Google Authentication reset successful',
  MFA_PHONE_RESET_SUCCESS: 'Phone Authentication reset successful',
  SOMETHING_WENT_WRONG: 'Something went wrong',
  RESET_PASSWORD_SUCCESS: 'We have emailed you a password reset link.',
  CUSTOM_BRANDING_UPDATED_SUCCESS: 'Custom branding have been updated successfully.'
};

export { VALIDATION_MESSAGE, TOAST_MESSAGE };
