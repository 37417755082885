import React from 'react';
import Skeleton from 'react-loading-skeleton';

const AddOnsCardSkeleton = () => {
  return (
    <div className="billing-box-skeleton">
      <div className="billing-box add-ons-box">
        <div className="add-ons-icon">
          <Skeleton width={46} height={46} />
        </div>
        <h4 className="cs-regular-h4 cs-neutral-100">
          <Skeleton width={60} />
        </h4>

        <h5 className="cs-regular-h5 cs-neutral-60">
          <Skeleton width={160} />
        </h5>
        <div className="sidebar-add-account">
          <Skeleton width={190} height={30} />
        </div>
        <span className="cs-regular-h5 cursor-pointer cs-primary">
          <Skeleton width={120} />
        </span>
      </div>
    </div>
  );
};

export default AddOnsCardSkeleton;
