import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from '../components/CapsyncIcon';
import CommonSpinner from './CommonSpinner';

/* ============================== DELETE MODAL ============================== */
const DeleteModal = ({
  show,
  onHandleClose,
  disabled,
  loading,
  onHandleConfirm,
  deleteBodyText,
  deleteButtonText
}) => {
  return (
    <Modal
      show={show}
      onHide={onHandleClose}
      className="cs-delete-modal cs-modal"
      dialogClassName="cs-md-modal"
      centered>
      <Modal.Header className="cs-md-modal">
        <Modal.Title className="cs-semi-bold-h3 cs-neutral-100">Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body className="cs-text-center">
        <span className="delete-filled icon cs-icon cs-danger">
          <CapsyncIcon title="delete-validation" size="100" />
        </span>
        <div className="cs-modal-text cs-regular-body-text-m cs-neutral-90">{deleteBodyText}</div>
      </Modal.Body>
      <Modal.Footer className="cs-modal-btn cs-center-btn">
        <Button onClick={onHandleClose} className="cs-btn-secondary md-btn cs-regular-h5">
          Cancel
        </Button>
        <Button
          onClick={onHandleConfirm}
          disabled={disabled}
          className="cs-btn-primary md-btn cs-regular-h5">
          {deleteButtonText}
          {loading && <CommonSpinner classParent="cs-modal-common-spinner" size={16} />}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// PROPS TYPE
DeleteModal.propTypes = {
  show: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onHandleClose: PropTypes.func,
  onHandleConfirm: PropTypes.func,
  deleteBodyText: PropTypes.string,
  deleteButtonText: PropTypes.string
};

export default DeleteModal;
