import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// CSS IMPORTS
import './Request.css';

// COMPONENT IMPORTS
import RequestTable from './RequestTable';
import Searchbar from '../../components/Searchbar';
import SkeletonRequest from '../components/skeleton/SkeletonRequest';

// HOOKS IMPORTS
import useDebounce from '../../hooks/useDebounce';

// API
import { getCompletedSignatureList, getPendingSignatureList } from '../../slices/requestSlice';
import { socket } from '../../config/Socket';

/* ============================== REQUEST PAGE ============================== */
const Request = () => {
  const dispatch = useDispatch();

  const radioName = [
    { name: 'Pending', value: 'pending' },
    { name: 'Completed', value: 'completed' }
  ];

  const [signatureList, setSignatureList] = useState({});
  const [tab, setTab] = useState(radioName[0].value);
  const [search, setSearch] = useState('');
  const [paginationData, setPaginationData] = useState({});
  const [columnDetails, setColumnDetails] = useState({});
  const [paginationDetails, setPaginationDetails] = useState({});
  const [onSubmit, setOnSubmit] = useState(false);
  const [rowData, setRowData] = useState({});
  useEffect(() => {
    onPaginationValueChange({
      page: 1,
      listType: tab,
      isPaginate: true,
      limit: 10
      // search: search
    });
    // setActiveRow(-1);
    return () => {
      setOnSubmit(false);
    };
  }, [tab, onSubmit]);

  useEffect(() => {
    socket.on('signature_status', (msg) => {
      onPaginationValueChange({
        page: 1,
        listType: tab,
        isPaginate: true,
        limit: 10
        // search: search
      });
    });

    return () => {
      socket.off('signature_status');
    };
  }, []);

  const getAllCollaborationUsersList = (params) => {
    if (params.listType === 'pending') {
      dispatch(getPendingSignatureList(params)).then((res) => {
        if (res.payload.status) {
          setPaginationDetails(res?.payload?.data?.pagination);
          setSignatureList(res?.payload?.data?.records);
        }
      });
    } else {
      dispatch(getCompletedSignatureList(params)).then((res) => {
        if (res.payload.status) {
          setPaginationDetails(res?.payload?.data?.pagination);
          setSignatureList(res?.payload?.data?.records);
        }
      });
    }
  };
  const onPaginationValueChange = (option) => {
    const paginationLimits = {
      listType: tab,
      page: option.page,
      limit: option.limit,
      isPaginate: option.limit !== 'all' ? true : false,
      search: search
    };
    getAllCollaborationUsersList(paginationLimits);
    setPaginationData(paginationLimits);
  };

  const startSearch = useDebounce(() => {
    if (tab === 'pending') {
      dispatch(getPendingSignatureList({ ...paginationData, search })).then((res) => {
        if (res.payload.status) {
          setSignatureList(res?.payload?.data?.records);
        }
      });
    } else {
      dispatch(getCompletedSignatureList({ ...paginationData, search })).then((res) => {
        if (res.payload.status) {
          setSignatureList(res?.payload?.data?.records);
        }
      });
    }
  }, 1000);

  useEffect(() => {
    startSearch();
  }, [paginationData, search]);
  const handleChangeTab = (value) => {
    setTab(value);
    setRowData({});
  };

  const handleTableSearch = (searchText) => {
    setSearch(searchText);
    if (columnDetails) {
      columnDetails.setRowStartIdx(0);
    }
    setPaginationData({
      page: 1,
      listType: tab,
      limit: columnDetails.rowsPerPage.value,
      isPaginate: columnDetails.rowsPerPage.value !== 'all' ? true : false
    });
  };
  const [loading] = useOutletContext();
  return (
    <section className="main-section request-main-section">
      <div className="container-fluid">
        {loading ? (
          <div className="request-skeleton">
            <SkeletonRequest />
          </div>
        ) : (
          <React.Fragment>
            <div className="cs-section-top-bar">
              <div className="cs-dashboard-title">
                <h1 className="cs-semi-bold-h1 cs-neutral-100">Requests</h1>
              </div>
            </div>
            <div className="cs-company-individual">
              <div className="cs-tabs-flex">
                <div className="cs-tabs">
                  {radioName.map((radioTab, id) => {
                    return (
                      <button
                        key={id}
                        type="button"
                        onClick={() => handleChangeTab(radioTab.value)}
                        className={`cs-stroke-default-tab cs-regular-h5 btn btn-primary${tab === radioTab.value ? ' cs-stroke-active' : ''}`}>
                        {radioTab.name}
                      </button>
                    );
                  })}
                </div>
                <Searchbar placeholder={'Search'} search={search} setSearch={handleTableSearch} />
              </div>
            </div>
            <RequestTable
              isPaginate={Object.entries(signatureList).length > 0 ? true : false}
              signatureList={signatureList}
              totalRecords={
                paginationDetails?.itemCount && paginationDetails?.itemCount > 0
                  ? paginationDetails?.itemCount
                  : signatureList?.length
              }
              onPaginationValueChange={onPaginationValueChange}
              setColumnDetails={setColumnDetails}
              setOnSubmit={setOnSubmit}
              tab={tab}
              rowData={rowData}
              setRowData={setRowData}
            />
          </React.Fragment>
        )}
      </div>
    </section>
  );
};

export default Request;
