import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ProcessBarBillingHistory = ({ type, label, used, total }) => {
  return (
    <div className="cs-storage">
      <div className="storage-info">
        <div className="storage-value storage-credit">
          <span className="cs-neutral-100 cs-regular-h4">{label}</span>
          <span className="cs-neutral-60 cs-regular-h5">
            {`${used || 0} of ${total || 0} purchased ${type} used`}
          </span>
        </div>
        <div className="storage-value">
          <span className="cs-neutral-100 cs-regular-sub-heading-xs">{used}</span>
          <span className="cs-neutral-100 cs-light-body-text-s">{total}</span>
        </div>
      </div>
      <div className="cs-progress-bar">
        <ProgressBar now={(used * 100) / total} />
      </div>
    </div>
  );
};
ProcessBarBillingHistory.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  used: PropTypes.number,
  total: PropTypes.number
};

export default ProcessBarBillingHistory;
