import React, { useState } from 'react';
import Modal from '../../../../components/Modal';
import PropTypes from 'prop-types';
import CapsyncIcon from '../../../../components/CapsyncIcon';
import { useDispatch } from 'react-redux';
import {
  getFirmPaymentDetails,
  upgradeDowngradeFileManagement,
  useFirmPaymentDetails
} from '../../../../slices/billingHistorySlice';
import { useUser } from '../../../../slices/authSlice';
import { toast } from 'react-toastify';

const UpgradeStorageModal = ({ setUpgradeStorageOpen, upgradeStorageOpen }) => {
  const dispatch = useDispatch();
  const firmPaymentDetails = useFirmPaymentDetails();
  const user = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const modalBody = () => {
    return (
      <>
        <div className="cs-storageplan">
          <span className="cs-primary">
            <CapsyncIcon size="100" title="folder-icon-filled" />
          </span>
          <p className="cs-regular-body-text-m neutral-90">
            {`Are you sure you want to ${firmPaymentDetails?.is_file_management_plan_upgraded ? 'downgrade' : 'upgrade'}  your storage plan?`}
          </p>
        </div>
      </>
    );
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const payload = {
        is_upgrade: firmPaymentDetails?.is_file_management_plan_upgraded ? false : true,
        price_id: firmPaymentDetails?.file_storage_upgrade_price_id,
        subscription_id: firmPaymentDetails?.subscription_id,
        firm_id: user?.firm_id
      };
      const response = await dispatch(upgradeDowngradeFileManagement(payload)).unwrap();
      if (response?.status) {
        await dispatch(getFirmPaymentDetails({ firm_id: user?.firm_id })).unwrap();
        setUpgradeStorageOpen(false);
        toast.success(
          firmPaymentDetails?.is_file_management_plan_upgraded
            ? 'Downgrade Successfully'
            : 'Upgrade Successfully'
        );
      } else {
        toast?.error(response?.errors);
        toast?.error(response?.message);
      }
    } catch (error) {
      console.log('error :>> ', error);
    } finally {
      setIsLoading(false);
    }
  };
  const closeModal = () => setUpgradeStorageOpen(false);
  return (
    <div>
      <Modal
        className="stripe-form cs-md-modal"
        show={upgradeStorageOpen}
        title={
          firmPaymentDetails?.is_file_management_plan_upgraded
            ? 'Downgrade storage plan'
            : 'Upgrade storage plan'
        }
        body={modalBody()}
        saveButtonLabel={
          firmPaymentDetails?.is_file_management_plan_upgraded ? 'Downgrade' : 'Upgrade'
        }
        cancelButtonLabel="Cancel"
        handleOnSave={handleSubmit}
        disabled={isLoading}
        loading={isLoading}
        buttonCenter={true}
        handleOnCancel={closeModal}
        isCloseButton={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="cs-md-modal"
        modalFooterClass="cs-center-btn"
      />
    </div>
  );
};
UpgradeStorageModal.propTypes = {
  setUpgradeStorageOpen: PropTypes.func,
  upgradeStorageOpen: PropTypes.bool
};
export default UpgradeStorageModal;
