import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Form, FormLabel } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from '../CapsyncIcon';
import CapsyncToolTip from '../CapsyncToolTip';

/* ============================== DROPDOWN ============================== */
const DropdownBox = (props) => {
  const {
    name,
    options,
    labelShow = true,
    value,
    placeholder,
    tooltip,
    className,
    errors,
    touched,
    label,
    handleChangeSelect,
    disabled,
    status,
    parentClassName = ''
  } = props;

  const handleSelect = (eventKey) => {
    handleChangeSelect(eventKey);
  };

  return (
    <div
      className={`cs-dropdown select-dropdown collaboration-permission-dropdown ${parentClassName}`}>
      <Form.Group className="cs-form-group cs-regular-body-text-m">
        {label && labelShow && <FormLabel>{label}</FormLabel>}
        <Dropdown onSelect={handleSelect} className="cs-form-dropdown">
          <Dropdown.Toggle
            variant="light"
            id={`dropdown-${name}`}
            className={`cs-regular-body-text-m ${className ? className : ''}`}
            disabled={disabled}>
            {value ? value.label : placeholder}
            <span className="cs-neutral-90">
              <CapsyncIcon title="chevron-down-outlined" size="18" />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <div className="cs-scroller">
              {options.map((option, index) => (
                <Dropdown.Item
                  key={index}
                  className={option.label === value.label ? 'dropdown-item-active' : ''}
                  eventKey={option.value}
                  active={option.label === value.label}
                  disabled={option.disabled}>
                  {option.label}
                  {tooltip && (
                    <span className={option.label === value.label ? 'cs-primary' : 'cs-neutral-90'}>
                      <CapsyncToolTip
                        Child={'info-outlined'}
                        placement={'top'}
                        message={
                          'Assertively deploy resource maximizing supply chains rather than highly efficient information.'
                        }
                        type="icon"
                        size="22"
                        width="100%"
                      />
                    </span>
                  )}
                </Dropdown.Item>
              ))}
            </div>
          </Dropdown.Menu>
        </Dropdown>
        {errors && errors[name] && touched[name] && (
          <span className="form-error-msg cs-light-body-text-s cs-danger">{errors[name]}</span>
        )}
        {status && status[name] && !errors[name] && (
          <span className="form-error-msg cs-light-body-text-s cs-danger">{status[name]}</span>
        )}
      </Form.Group>
    </div>
  );
};

// PROPS TYPE
DropdownBox.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      disabled: PropTypes.bool
    })
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.bool,
  errors: PropTypes.object,
  touched: PropTypes.object,
  labelShow: PropTypes.bool,
  handleChangeSelect: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  status: PropTypes.object,
  parentClassName: PropTypes.string
};

export default DropdownBox;
